.table-v2 {
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2) !important;
    border-radius: 4px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 0px;
    border: 1px solid !important;
    border-color: #dadce4 !important;
    transition: box-shadow 0.3s ease-in-out;
    min-height: 20px;
    margin-bottom: 20px;
}

.table-v2:hover {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2) !important;
}

.header-v2 {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 30px;
    text-align: center;
    padding: 20px 20px;
    padding-top: 0px !important;

    color: #000000;
}

.table-v2 .list-v2 {
    margin-bottom: 0;
    padding-left: 0;
    border-color: #dadce4;
    background-color: #fff;
    border: 1px solid transparent;
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
    margin-bottom: 0px;
}

.list-v2-header {
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding: 45px 35px 0px 35px;
    color: #000000;
}

.list-v2-header.pTop0 {
    padding-top: 0;
}

.table-v2 .list-v2-item {
    border-right: 0px !important;
    border-left: 0px !important;
    border-radius: 0 !important;
    padding: 20px 20px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    border-width: 1px 0;
    position: relative;
    display: block;
    background: #fff;
    border-top: 1px solid #dadce4;
    text-decoration: none;
    cursor: move;
    margin-left: 0px;
    margin-right: 0px;
    color: #3B434B;
}

.table-v2 .list-v2-item:last-child {
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    margin-bottom: 0;
}

.table-v2.table-condensed .list-v2-item {
    padding-top: 10px;
    padding-bottom: 10px;
}


.table-v2 .list-v2 {
    border-radius: 0 !important;
    border-bottom-right-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    margin-left: -30px;
    margin-right: -30px;
    margin-bottom: 0px;
}

.list-v2-item[ng-click]:hover {
    background: #F1F8FC;
    border-left: 5px solid #1f57ff !important;
    padding-left: 16px;
    text-decoration: none;
    margin-left: -1px;
}
.list-v2-item:hover {
    outline: 0;
}

input:focus::placeholder {
    color: transparent;
}

a.disabled {
    pointer-events: none;
    color: gray !important;
    cursor: not-allowed;
}
