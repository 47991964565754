// Vendor
@import '@ng-select/ng-select/themes/default.theme.css';
@import 'angular-block-ui/dist/angular-block-ui.css';
@import 'vendor/ui-select/select.min.css';
@import './animation.css';
@import './css/v2.css';
@import '../app/feedonomics-theme.css';
@import '../assets/css/input-with-button.scss';

// Use Bootstrap 5 redefined variables for now
@import 'frontend/src/styles/bootstrap5/variables';

// Convert rem sizes to px for Bootstrap 3 support
$font-size-base:        14px;
$h1-font-size:          28px;
$h2-font-size:          24px;
$h3-font-size:          20px;
$h4-font-size:          18px;
$h5-font-size:          16px;
$h6-font-size:          14px;
$grid-gutter-width:     24px;
$border-radius:         4px;
$border-radius-lg:      8px;
$tooltip-arrow-width:   5px;
$popover-arrow-width:   10px;

// Import sass version of bootstrap 3
$icon-font-path: '../../fonts/bootstrap/';
@import 'bootstrap-sass';

// Post-load Bootstrap 3 additions
@import 'frontend/src/styles/bootstrap3/bootstrap-fdx';

// Load additional utility classes
@import 'frontend/src/styles/feedonomics/fdx-utils.scss';

@import 'app.css';

/**
 * AG Grid Font Face
 * Font face needs to be in the head of the document to work.
 * Replace with ag-theme-alpine.css once Angular upgrade is complete.
 */
@import 'ag-grid-community/styles/agGridAlpineFont.css';
