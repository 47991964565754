@use 'frontend/src/styles/bootstrap5/variables';

.ui-select-bootstrap .ui-select-choices-row>span {
    color: variables.$dark;
}

.ui-select-bootstrap .ui-select-choices-row>span:focus, .ui-select-bootstrap .ui-select-choices-row>span:hover {
    background-color: variables.$primary-100;
}

.ui-select-bootstrap .ui-select-choices-row.active>span {
    color: variables.$dark;
    background-color: variables.$primary-200;
}
