@use 'frontend/src/styles/bootstrap5/variables';

@each $color, $value in variables.$base-theme-colors {
    $map: ();
    @if (map-has-key(variables.$color-maps, #{$color})) {
        $map: map-get(variables.$color-maps, #{$color});

        .btn-#{$color} {
            @include button-variant(
                $background: map-get($map, '600'),
                $border: map-get($map, '700'),
                $color: color-contrast(map-get($map, '600'))
            );
        }

        .btn-outline-#{$color} {
            @include button-variant(
                $background: map-get($map, '100'),
                $border: map-get($map, '300'),
                $color: map-get($map, '600')
            );

            &.btn-flagged {
                background: linear-gradient(45deg, map-get($map, '100') 80%, map-get($map, '600') -1%);

                &:disabled, &.disabled {
                    background: linear-gradient(45deg, map-get($map, '100') 80%, rgba(color-contrast(map-get($map, '100')), variables.$btn-disabled-opacity) -1%);
                }

                &:hover {
                    background: linear-gradient(45deg, map-get($map, '200') 80%, map-get($map, '700') -1%);
                }

                &:active {
                    background: linear-gradient(45deg, map-get($map, '600') 80%, variables.$white -1%);
                }
            }
        }
    }
}

.btn-link {
    text-decoration: none;
    &:hover, &:active, &:focus-visible {
        text-decoration: underline;
    }

    &:hover {
        color: variables.$primary-700 !important;
    }

    &:active, &:focus-visible {
        color: variables.$primary-800 !important;
    }

    &.text-secondary {
        &:hover {
            color: variables.$secondary-700 !important;
        }

        &:active, &:focus-visible {
            color: variables.$secondary-800 !important;
        }
    }

    &.text-darker-secondary {
        color: variables.$secondary-700 !important;

        &:hover {
            color: variables.$secondary-800 !important;
        }

        &:active, &:focus-visible {
            color: variables.$secondary-900 !important;
        }
    }

    &.text-light {
        &:hover {
            color: variables.$secondary-300 !important;
        }

        &:active, &:focus-visible {
            color: variables.$secondary-400 !important;
        }
    }
}

.btn fa-icon:not(.fa-icon-right) {
    margin-right: map-get(variables.$spacers, 2);
}

.btn fa-icon.fa-icon-right {
    margin-left: map-get(variables.$spacers, 2);
}

.btn fa-icon.no-margin {
    margin-right: 0;
}

.btn.btn-floating {
    border-radius: 50%;
    box-shadow: 0px 4px 8px rgba(39, 40, 51, 0.2);
    padding: map-get(variables.$spacers, 2);
    display: flex;
    align-items: center;
    justify-content: center;

    width: variables.$floating-btn-size;
    height: variables.$floating-btn-size;

    &.btn-sm {
        width: variables.$floating-btn-sm-size;
        height: variables.$floating-btn-sm-size;
    }

    &.btn-lg {
        width: variables.$floating-btn-lg-size;
        height: variables.$floating-btn-lg-size;
    }
}

.input-group>button.btn {
    border: variables.$input-border-width solid variables.$input-border-color;
}

.unstyled-button {
    display: inline-block;
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
    box-shadow: none;
}
