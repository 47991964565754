
.top-pending-alert{
    animation: shake 2.5s;
    height:26px;
    width:26px;
    margin-top:-8px;
}

.dropdown-pending-alert{
    animation:shake 2.5s;
    animation-iteration-count:infinite;
    height:18px;
    width:18px;
    margin-top:-8px;
}

@keyframes shake {
    0% { transform: translate(0px, 0px) rotate(0deg); }
    25% { transform: translate(0px, 0px) rotate(0deg); }
    26% { transform: translate(1px, 0px) rotate(1deg); }
    27% { transform: translate(1px, 0px) rotate(2deg); }
    28% { transform: translate(1px, 0px) rotate(1deg); }
    29% { transform: translate(0px, 0px) rotate(0deg); }
    30% { transform: translate(-1px, 0px) rotate(-1deg); }
    31% { transform: translate(-1px, 0px) rotate(-2deg); }
    32% { transform: translate(-1px, 0px) rotate(-1deg); }
    33% { transform: translate(0px, 0px) rotate(0deg); }
    34% { transform: translate(1px, 0px) rotate(1deg); }
    35% { transform: translate(1px, 0px) rotate(2deg); }
    36% { transform: translate(1px, 0px) rotate(1deg); }
    37% { transform: translate(0px, 0px) rotate(0deg); }
    38% { transform: translate(-1px, 0px) rotate(-1deg); }
    39% { transform: translate(-1px, 0px) rotate(-2deg); }
    40% { transform: translate(-1px, 0px) rotate(-1deg); }
    41% { transform: translate(0px, 0px) rotate(0deg); }
    100% { transform: translate(0px, 0px) rotate(0deg); }
}
