@use 'frontend/src/styles/bootstrap5/variables';
@use 'sass:math';
@use 'sass:string';

body {
    background-color: variables.$secondary-100;
    font-size: variables.$font-size-base;
}

pre.code-block {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 13px;
}

@each $color, $value in variables.$base-theme-colors {
    $map: ();

    @if (map-has-key(variables.$color-maps, #{$color})) {
        $map: map-get(variables.$color-maps, #{$color});

        .fdx-header-bg-#{$color} {
            background-color: map-get($map, '100');
            color: map-get($map, '600');

            .btn-close {
                color: map-get($map, '600');
                background: transparent
                    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23" + string.slice(
                            #{map-get($map, '600')},
                            2
                        ) + "'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
                    center/1em
                    auto
                    no-repeat;
            }
        }
    }
}

.min-width-0 {
    min-width: 0;
}

.min-height-0 {
    min-height: 0;
}

.remove-height {
    // For use with flex-column grow elements to prevent content overflow
    height: 0;
}

.fdx-expand-wrapper {
    padding: 0.5rem;
    margin: -0.5rem;
}

.fdx-bubble {
    display: flex;
    align-items: flex-start;
    position: relative;

    .fdx-bubble-connect::after {
        content: '';
        background-color: variables.$secondary-200;
        height: calc(100% + 17px);
        width: 5px;
        position: absolute;
        top: calc(1rem + 17px);
        left: 95px;
    }

    .fdx-bubble-label {
        padding: calc(0.375rem + 1px);
        font-weight: 700;
        line-height: 1.5;
        background-color: variables.$secondary-200;
        border-radius: 0.25rem;
        text-align: center;
        width: 112px;
        height: 35px;
        margin: 1rem 3rem 1rem 0;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            top: calc(18px - 0.75rem); // 23px (half of label height) - 0.75rem (border width)
            left: calc(112px + 2.375rem); // 125px (label width) + 2rem (margin) + 0.375 (half of border width)
            border: calc(0.75rem - 1px) solid transparent; // -1px to not run over
            transform: rotate(135deg);
            box-shadow: 1px 1px 0 0 variables.$secondary-200;
            background: variables.$white;
        }
    }

    .fdx-bubble-content {
        padding: 1rem;
        border: 1px solid variables.$secondary-200;
        border-radius: 0.5rem;
        width: 0;
        min-height: 74px; //46px (label height) + 32px (2x padding width) - 4px (4x 1px borders)
        flex-grow: 1;
    }
}

.fdx-flex-1 {
    // Can be used to make flex fields equal length
    flex: 1;
    /* flex-grow: 1; */
    /* flex-shrink: 1; */
    /* flex-basis: 0%; */
}

.fdx-btn-padding-x {
    padding-left: variables.$btn-padding-x;
    padding-right: variables.$btn-padding-x;
}

.fdx-btn-padding-y {
    padding-top: variables.$btn-padding-y;
    padding-bottom: variables.$btn-padding-y;
}

.header-btn {
    border: 0;
    opacity: 75%;
    &:hover {
        opacity: 100%;
    }
}

.fdx-vertical-line {
    border-left: 2px solid variables.$primary;
    height: 24px;
    display: inline-block;
    opacity: 25%;
}

$drawer-margin: map-get(variables.$spacers, 4);

.fdx-drawer-container {
    display: flex;
    gap: $drawer-margin;

    .fdx-drawer-content {
        min-width: 0;
        flex: 1;
    }

    .fdx-drawer {
        position: sticky;
        top: 0;
        bottom: 0;
        margin-top: -$drawer-margin;
        margin-bottom: -$drawer-margin;

        &.fdx-drawer-left {
            left: 0;
            margin-left: -$drawer-margin;
        }

        &.fdx-drawer-right {
            right: 0;
            margin-right: -$drawer-margin;
        }
    }
}

.collapsing-width {
    position: relative;
    overflow: hidden;
    -webkit-transition: width 0.35s ease;
    -moz-transition: width 0.35s ease;
    -o-transition: width 0.35s ease;
    transition: width 0.35s ease;
}

.text-success-500 {
    color: variables.$success-500;
}

.text-secondary-500 {
    color: variables.$secondary-500;
}

.text-secondary-600 {
    color: variables.$secondary-600;
}

.text-primary-500 {
    color: variables.$primary-500 !important;
}

.fdx-gray-caps {
    text-transform: uppercase;
    font-weight: variables.$font-weight-medium;
    letter-spacing: 0px;
    color: variables.$secondary-600;
    font-size: 0.75rem;
}

.fdx-gray-caps-base {
    @extend .fdx-gray-caps;
    font-size: variables.$font-size-base;
}

.white-space-pre {
    white-space: pre;
}

.white-space-break-spaces {
    white-space: break-spaces;
}

.fdx-small {
    font-size: variables.$small-font-size;
    font-weight: variables.$font-weight-normal;
    color: variables.$secondary-500;
}

.negative-offcanvas-margin {
    margin-left: -(variables.$offcanvas-padding-x);
    margin-right: -(variables.$offcanvas-padding-x);
}

.fdx-grid-full-screen {
    position: absolute;
    height: 100vh;
    width: 100vw;
    border-radius: 0;
    z-index: 1031; // Just above sticky and fixed headers.
    top: 0;
    left: 0;
}

.fdx-category-name {
    font-weight: variables.$font-weight-medium;
}

.fdx-category-name-amazon {
    font-weight: variables.$font-weight-bold;
}

// Use with "@extend .fdx-active-tag;" where necessary to add active tag to list of items
.fdx-active-tag {
    content: '';
    background-color: variables.$primary-500;
    width: 4px;
    height: 80%;
    border-radius: 0 variables.$border-radius-pill variables.$border-radius-pill 0;
    position: absolute;
    top: 10%;
    left: 0;
}

$resizer-size: 12px;
$handle-size: 4px;

.fdx-resizer-horizontal {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -(calc($resizer-size / 2)) !important;
    width: $resizer-size;
    cursor: ew-resize;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: calc(($resizer-size - $handle-size) / 2);
        width: $handle-size;
        background: variables.$secondary;
        opacity: 0;
        transition: opacity 200ms;
    }
}

.fdx-resizer-vertical {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -(calc($resizer-size / 2)) !important;
    height: $resizer-size;
    z-index: 2;
    cursor: ns-resize;

    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: calc(($resizer-size - $handle-size) / 2);
        height: $handle-size;
        background: variables.$secondary;
        opacity: 0;
        transition: opacity 200ms;
    }
}

.fdx-resizer-horizontal, .fdx-resizer-vertical {
    &:hover {
        &::after {
            opacity: 0.2;
        }
    }

    &:active {
        &::after {
            opacity: 0.4;
        }
    }
}

$widths: 0, 10, 20, 30, 40, 60, 70, 80, 90;

@each $width in $widths {
    @if ($width == 0) {
        .w-0 {
            width: 0 !important;
        }
    } @else {
        .w-#{$width} {
            width: math.percentage(math.div($width, 100)) !important;
        }
    }
}

$nav-header-height: 63px;
