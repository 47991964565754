// Primary
$primary-100:   #eef2ff;
$primary-200:   #d1dcff;
$primary-300:   #acbfff;
$primary-400:   #85a2ff;
$primary-500:   #4a77ff;
$primary-600:   #1f57ff;
$primary-700:   #1939c2;
$primary-800:   #142796;
$primary-900:   #101b72;

// Secondary
$secondary-100:   #f1f2f5;  // Use $light theme color reference instead of $secondary-100
$secondary-200:   #dadce4;
$secondary-300:   #bdc1ce;
$secondary-400:   #a1a5b8;
$secondary-500:   #7c7f97;
$secondary-600:   #62647d;
$secondary-700:   #484a5c;
$secondary-800:   #353645;
$secondary-900:   #262832;  // Use $dark theme color reference instead of $secondary-900

// Success
$success-100:   #e8f6ea;
$success-200:   #bbe8c6;
$success-300:   #7ed29a;
$success-400:   #47bb7a;
$success-500:   #17935e;
$success-600:   #167450;
$success-700:   #14553e;
$success-800:   #103e30;
$success-900:   #0d2d24;

// Warning
$warning-100:   #fff0da;
$warning-200:   #fed79c;
$warning-300:   #fcb33d;
$warning-400:   #ea9214;
$warning-500:   #c06c08;
$warning-600:   #9c530b;
$warning-700:   #733d0e;
$warning-800:   #4c311b;
$warning-900:   #30261d;

// Danger
$danger-100:   #ffeff0;
$danger-200:   #fbd2d5;
$danger-300:   #f7adb3;
$danger-400:   #f28792;
$danger-500:   #dd4f61;
$danger-600:   #bd3044;
$danger-700:   #911c2f;
$danger-800:   #6f1020;
$danger-900:   #411d23;

// Info
$info-100:   #f3feff;
$info-200:   #a7e8ec;
$info-300:   #6fcfdf;
$info-400:   #3fb4c9;
$info-500:   #128da4;
$info-600:   #0d6f84;
$info-700:   #0e5262;
$info-800:   #163c44;
$info-900:   #152b30;

// Discovery
$discovery-100:   #f3f0fe;
$discovery-200:   #dfd9fa;
$discovery-300:   #c2baf4;
$discovery-400:   #a69deb;
$discovery-500:   #7f74d4;
$discovery-600:   #6459b7;
$discovery-700:   #4a408a;
$discovery-800:   #372f65;
$discovery-900:   #282249;

// Color maps
$primary-map: (
    '100': $primary-100,
    '200': $primary-200,
    '300': $primary-300,
    '400': $primary-400,
    '500': $primary-500,
    '600': $primary-600,
    '700': $primary-700,
    '800': $primary-800,
    '900': $primary-900
);

$secondary-map: (
    '100': $secondary-100,
    '200': $secondary-200,
    '300': $secondary-300,
    '400': $secondary-400,
    '500': $secondary-500,
    '600': $secondary-600,
    '700': $secondary-700,
    '800': $secondary-800,
    '900': $secondary-900
);

$success-map: (
    '100': $success-100,
    '200': $success-200,
    '300': $success-300,
    '400': $success-400,
    '500': $success-500,
    '600': $success-600,
    '700': $success-700,
    '800': $success-800,
    '900': $success-900
);

$warning-map: (
    '100': $warning-100,
    '200': $warning-200,
    '300': $warning-300,
    '400': $warning-400,
    '500': $warning-500,
    '600': $warning-600,
    '700': $warning-700,
    '800': $warning-800,
    '900': $warning-900
);

$danger-map: (
    '100': $danger-100,
    '200': $danger-200,
    '300': $danger-300,
    '400': $danger-400,
    '500': $danger-500,
    '600': $danger-600,
    '700': $danger-700,
    '800': $danger-800,
    '900': $danger-900
);

$info-map: (
    '100': $info-100,
    '200': $info-200,
    '300': $info-300,
    '400': $info-400,
    '500': $info-500,
    '600': $info-600,
    '700': $info-700,
    '800': $info-800,
    '900': $info-900
);

$discovery-map: (
    '100': $discovery-100,
    '200': $discovery-200,
    '300': $discovery-300,
    '400': $discovery-400,
    '500': $discovery-500,
    '600': $discovery-600,
    '700': $discovery-700,
    '800': $discovery-800,
    '900': $discovery-900
);

$color-maps: (
    'primary': $primary-map,
    'secondary': $secondary-map,
    'success': $success-map,
    'warning': $warning-map,
    'danger': $danger-map,
    'info': $info-map,
    'discovery': $discovery-map,
);
