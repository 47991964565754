html {
	font-size: unset !important;
}

b,
label,
strong,
.control-label,
.font-weight-bold,
.bold {
	font-weight: 500;
}

.font-weight-normal {
	font-weight: normal;
}

.feed-header {
	background-color: white;
	padding:5px;
}

.bg-white {
    background-color: white !important;
}

.page-header {
	margin-top:0px;
}

#main-content-container {
	padding: 1.5rem;    /* $spacers 3 */
	background: transparent !important;
}

#main-content-container:empty {
    padding: 0;
}

#main-content-container.fit-iframe {
	padding: 0;
    margin-bottom: 0;
}

.container-fluid {
	display: flex;
	width: 100%;
}

.flex-container {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.flex-container .flex-item {
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
}

.navbar-left {	/*navbar-right content would cover half of the border-bottom of a selected item from navbar-left*/
	display: flex;
	width: 100%;
	flex-wrap: wrap;
}

.navbar-right-item {
	margin-left: auto;	/*right align last item in flex box*/
}

.navbar-right-item > a:not(.has-avatar) {
	padding-right: 5px;
}

.navbar-right-item ul.dropdown-menu {
	left: auto;
	right: 0;
}

@media (min-width: 768px) {
	.navbar-collapse {
        width: auto;
        border-top: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
	}
	.navbar-collapse.collapse {
	    display: flex !important;
	    flex-grow: 1;
	}
	.navbar-collapse.in {
	    overflow-y: visible;
	}
}

.well {
	padding:15px;
	background:white !important;
	border: 1px solid !important;
	border-color: #dadce4 !important;
	border-radius: 0.5rem !important;
	box-shadow: 0 1px 5px 0 rgba(45,62,80,0.12) !important;
	transition: box-shadow 0.3s ease-in-out;
}

.well:hover{
	box-shadow: 0 5px 25px rgba(45,62,80,0.12) !important;
}

.well-no-glow{
	padding:15px;
	background:white;
	border: 1px solid;
	border-color: #dadce4;
	border-radius: 0.5rem;
	box-shadow: 0 1px 5px 0 rgba(45,62,80,0.12);
	transition: box-shadow 0.3s ease-in-out;
}

.card {
    background-color: #fff;
    background-clip: border-box;
	border: 1px solid #dadce4;
	border-radius: 0.5rem;
}

.card .card-header {
	border-bottom: 1px solid #dadce4;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	padding: 0.5rem 1rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}

.card .card-header.card-header-block {
	display: block;
}

.card .card-header h3 {
	font-size: 24px;
	margin: 0 auto 0 0;
}

.card .card-header h6 {
	margin: 0;
}

.card .card-header h6 + button {
	margin-left: 20px;
}

.card .card-body {
	padding: 15px;
}

.card .card-body.p0 {
	padding: 0;
}

.card .card-footer {
	background: #FFF;
	border-top: 1px solid #dadce4;
	padding: 1rem;
	text-align: right;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.card .card-footer .btn .far,
.card .card-footer .btn .fas {
	margin-right: 5px;
}

.card-row {
	align-items: flex-start;
	display: flex;
	width: 100%;
}

.card-row.card-row-stretch {
	align-items: stretch;
}

.card-row .card {
	flex: 1 1 auto !important;
	width: 100%;
}

.card-row .card + .card {
	margin-left: 20px;
}

/* transformer input border background */
.CodeMirror{
	border: 1px solid #ced4da;
}

.CodeMirror .CodeMirror-placeholder {
	color: #dadce4;
}

.CodeMirror-hints {
    z-index: 1050;
}

	/* Fix selectize hover over currently selected element */
.ui-select-choices-row:hover {
	color:black;
}

.ui-select-match {
    border-radius: 0.25rem;
}

.ui-select-bootstrap>.ui-select-choices,
.ui-select-selectize>.ui-select-choices
{
	max-height:500px;
}


.modified {
	background-color:rgb(239,239,239) !important;
}

.top-buffer {
	margin-top:15px;
}

.m0 {
	margin: 0;
}
.db_group + .db_group{
	border-top: 1px solid #dadce4;
}

.db_group_member{
	margin-left:20px;
}


.mBottom0 {
	margin-bottom:0px;
}
.mBottom10 {
	margin-bottom:10px;
}
.mBottom20 {
	margin-bottom:20px;
}

.mTop0 {
	margin-top:0px;
}
.mTop5 {
	margin-top:5px;
}
.mTop10 {
	margin-top:10px;
}
.mTop15 {
	margin-top: 15px;
}
.mTop20 {
	margin-top:20px;
}
.mTop35 {
	margin-top:35px;
}
.mTop40 {
	margin-top:40px;
}
.mTop45 {
	margin-top: 45px;
}

.mRight0 {
	margin-right: 0 !important;
}
.mRight5 {
	margin-right: 5px;
}
.mRight15 {
	margin-right: 15px;
}

.mBottom0 {
	margin-bottom:0px;
}
.mBottom5 {
	margin-bottom:5px;
}
.mBottom10 {
	margin-bottom:10px;
}
.mBottom15 {
	margin-bottom:15px;
}
.mBottom20 {
	margin-bottom:20px;
}

.mBottom50 {
	margin-bottom:50px;
}
.mRight5 {
	margin-right:5px;
}
.mRight10 {
	margin-right:10px;
}

.mLeft0 {
	margin-left:0px !important;
}
.mLeft5 {
	margin-left:5px;
}
.mLeft10 {
	margin-left:10px;
}
.mLeft20 {
	margin-left:20px;
}
.mLeft40 {
	margin-left:40px;
}

.p0 {
	padding: 0;
}

.pTop0 {
	padding-top: 0;
}
.pTop5 {
	padding-top: 5px;
}
.pTop15 {
	padding-top: 15px;
}

.pRight0 {
	padding-right: 0;
}

.pBottom0 {
	padding-bottom: 0;
}
.pBottom5 {
	padding-bottom: 5px;
}
.pBottom15 {
	padding-bottom: 15px;
}

.p30 {
	padding: 30px;
}
.pLeft0,
.table .pLeft0 {
	padding-left: 0;
}
.pLeft5 {
	padding-left: 5px;
}
.pLeft10 {
	padding-left: 10px;
}

.w-100 {
	width: 100%;
}

.w-auto {
	width: auto !important;
}

.cursor-pointer{cursor:pointer;}

.wordwrap {
	white-space: pre-wrap;
	word-wrap: break-word;
}


pre {
	overflow: auto;
	word-wrap: normal;
	white-space: pre;
}

.pre-wrap {
	white-space: pre-wrap;
}

.overflow-auto {
	overflow: auto;
}

.table thead tr th, .table tbody tr th {
	font-weight: 600;
    background-color: #f1f2f5;
}

.table-fixed {
	table-layout: fixed;
}

.table-middle tbody tr td {
	vertical-align: middle;
}

.table thead tr th.col-min-width {
	width: 1px;
	white-space: nowrap;
}

.table-hover thead tr:hover th,
.table-hover tbody tr:hover th {
	background-color: #dadce4;
}

.table-hover tbody tr:hover td {
	background-color: #eef2ff;
}

.table-outlined {
	border: 1px solid #dadce4;
}

.table-outlined tbody tr td {
	border: none;
}

.table-outlined tbody tr.bordered td,
.table-outlined tbody tr td.bordered {
	background-color: transparent;
	border-top: 1px solid #dadce4;
	border-bottom: 1px solid #dadce4;
}
.btn {
	border-radius: 0.25rem;
}

.padded_row {
	margin-right:0px !important;
	margin-left:0px !important;
	padding:8px;
}

.border-none {
	border: none;
}

.border_bottom {
	border-bottom: 1px solid #dadce4;
}

.margin_bottom {
	margin-bottom:55px;
}

.red {
	color:#bd3044;
}
.gray {
	color:gray
}
.blue {
	color:#0d6f84;
}
.link_blue {
	color:#1f57ff;
}
.green {
	color:#167450;
}

.modified_border_color {
	border: 2px solid !important;
	border-color:#1f57ff !important;
}

.error_border_color {
	border-color:#953b39 !important;
}

.error_background_color {
	background-color:#c26565 !important;
}

.error_color, .text-danger {
	color:#bd3044;
}
.warning_color, .text-warning {
	color:#9c530b;
}

.error{
	background-color:#c26565 !important;
	color:white;
	border-radius:0px;
	font-weight: bold;
	padding: 15px;
	font-size: 14px;
}

.warning{
	background-color:#FFF4EC !important;
	color:#B95000;
	border-radius: 5px;
	border: 2px solid #fbb294;
	font-weight: bold;
	padding: 15px;
	font-size: 14px;
}

.error_text{
	margin-top:15px;
	text-align: left !important;
}

.text-uppercase {
	text-transform: uppercase;
}

.text-capitalize {
	text-transform: capitalize;
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

/* flip robot animation */
.error_flip_container{
}
.error_flip_container.hover .error_flipper {
	transform: rotateY(180deg);
}
.error_flip_container,
.error_front,
.error_back {
	width: 50px;
	height: 50px;
}
.error_flipper {
	transition: 1.0s;
	transform-style: preserve-3d;
	position: relative;
}
.error_front,
.error_back {
	backface-visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
}
.error_front {
	z-index: 2;
	transform: rotateY(0deg);
}
.error_back {
	transform: rotateY(180deg);
}

.help{
	font-size:18px;
}

.ok_color {
	color:#167450;
}

.no-fade {
	transition:none;
}


.modal-header, .modal-footer{
	border:0px;
}

.modal.fade .modal-dialog, .modal.in .modal-dialog {
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	transform: translate(0, 0);
	transition: none;
}

.modal-dialog .modal-title .glyphicon {
	font-size: 20px;
}

.modal-backdrop.fade .modal-backdrop-dialog, .modal-backdrop.in .modal-backdrop-dialog {
	-webkit-transform: translate(0, 0);
	-ms-transform: translate(0, 0);
	transform: translate(0, 0);
	transition: none;
}

/*
.modal.fade {
	opacity: 1;
}
.modal-backdrop.fade {
	opacity: 1;
}
*/

/* Success Message: fade-in and fade-out */
.animate-show.ng-hide-add.ng-hide-add-active,
.animate-show.ng-hide-remove.ng-hide-remove-active {
	-webkit-transition: opacity linear 0.75s;
	transition: opacity linear 0.75s;
}

.animate-show.ng-hide {
	/*line-height: 0;*/
	opacity: 0;
	padding: 0 10px;
}

.nav {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    list-style: none;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
    border-bottom: none;
}

.nav::before {
    content: none;
}

.nav-tabs {
    border-bottom: none;
    padding-bottom: 1px;
    column-gap: 1.25rem;
    row-gap: 0.5rem;
}

.nav-tabs > li {
    cursor: pointer;
}

.nav-tabs > li > a,
.nav-tabs > li > a:focus,
.nav-tabs > li > a:hover,
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
    background-color: inherit !important;
	border: 0 !important;
    cursor: pointer !important;
    font-weight: 500;
    border-bottom: 2px solid transparent !important;
    padding: 0.25rem 0 !important;
}

.nav-tabs > li > a {
    color: #62647d !important;     /*variables.$secondary*/
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
    color: #5b5c6b !important;     /*shade-color(variables.$secondary, 15%)*/
    background-color: inherit;
}

.nav-tabs > li.active > a {
    color: #262832 !important;     /*variables.$dark*/
    border-bottom-color: #1f57ff !important;  /*variables.$primary*/
}

.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
    color: #1e1f27 !important;     /*shade-color(variables.$dark, 15%)*/
    border-bottom-color: #1f57ff !important;  /*variables.$primary*/
}

ul.dropdown-menu {
	margin-left:-1px !important;
}

/* Next and Previous data/transformed data border radius */
.pager li>a{
	border-radius: 4px;
}

.form-control{
	border-radius: 0.25rem !important;
}

h3 {
	font-size:30px;
}

.page-title {
	font-size: 30px;
	font-weight: 500;
	text-align: center;
}

.page-title.text-left {
	text-align: left;
}

.ui-select-multiple.ui-select-container {
	padding:5px;
}


.wide-popover .popover {
	max-width:300px;
	width:300px;
}

.lds-roller {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;
}
.lds-roller div {
	animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	transform-origin: 32px 32px;
}
.lds-roller div:after {
	content: " ";
	display: block;
	position: absolute;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: #1f57ff;
	margin: -3px 0 0 -3px;
}
.lds-roller div:nth-child(1) {
	animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
	top: 50px;
	left: 50px;
}
.lds-roller div:nth-child(2) {
	animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
	top: 54px;
	left: 45px;
}
.lds-roller div:nth-child(3) {
	animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
	top: 57px;
	left: 39px;
}
.lds-roller div:nth-child(4) {
	animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
	top: 58px;
	left: 32px;
}
.lds-roller div:nth-child(5) {
	animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
	top: 57px;
	left: 25px;
}
.lds-roller div:nth-child(6) {
	animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
	top: 54px;
	left: 19px;
}
.lds-roller div:nth-child(7) {
	animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
	top: 50px;
	left: 14px;
}
.lds-roller div:nth-child(8) {
	animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
	top: 45px;
	left: 10px;
}
@keyframes lds-roller {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.p-unset {
	position: unset;
}

.d-block {
	display: block;
}

.d-inline-block {
	display: inline-block;
}

.d-flex {
	display: flex;
}

.d-flex.align-items-center {
	align-items: center;
}

.d-flex.align-items-start {
	align-items: flex-start;
}

.d-flex.align-items-stretch {
	align-items: stretch;
}

.d-flex.justify-content-between {
	justify-content: space-between;
}

.d-flex.justify-content-center {
	justify-content: center;
}

.d-flex.align-items-end {
	align-items: flex-end;
}

.d-flex .flex-grow-1 {
	flex-grow: 1;
}

.d-flex .flex-shrink-1 {
	flex-shrink: 1;
}

.d-flex .flex-fill {
	flex: 1 1 0 !important;
}

.d-flex .align-self-end {
	align-self: flex-end;
}

.d-flex .align-self-center {
	align-self: center;
}

.nowrap {
	white-space: nowrap;
}

.tooltip .tooltip-inner {
	white-space: pre-line;
	text-align: left;
}

.transformer-toggle-group {
	letter-spacing: 0.3px;
}

.transformer-toggle-group .btn {
	background: #1f57ff;
	border-color: #1f57ff;
	color: #FFF;
	cursor: default;
}

.transformer-toggle-group .btn.inactive {
	background: #FFF;
	border-color: #FFF;
	color: #b1afaf;
	cursor: pointer;
}

.transformer-toggle-group .btn.inactive:hover {
	background: #f7f3f3;
	border-color: #f7f3f3;
}

.transformer-wizard {
	color: #000;
	position: relative;
}

.transformer-wizard .transformer-wizard-title {
	background: #2690d0;
	border-radius: 3px;
	color: white;
	font-size: 16px;
	font-weight: 500;
	left: -3px;
	letter-spacing: 0.9px;
	padding: 3px 9px;
	position: absolute;
	text-transform: uppercase;
	top: -3px;
	z-index: 1;
}

.transformer-wizard .saving_adding_message {
	position: relative;
}

.transformer-wizard .conditional-column {
	text-align: center;
	text-transform: uppercase;
	width: 100px;
}

.transformer-wizard .join-column {
	align-self: center;
	background-color: #ccc;
	height: 1px;
	position: relative;
	width: 60px;
}

.transformer-wizard .join-column div {
	background: #fff;
	color: #404040;
	display: inline-block;
	font-size: 11px;
	font-weight: 500;
	left: 50%;
	letter-spacing: 1px;
	padding: 0 2px;
	position: absolute;
	text-transform: uppercase;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 3;
}

.transformer-wizard .join-column div.dropdown.open {
	z-index: 4;
}

.transformer-wizard .join-column div a {
	color: #404040;
	white-space: nowrap;
}

.transformer-wizard .join-column div a[dropdown-toggle]:hover {
	opacity: 0.7;
	text-decoration: none;
}

.transformer-wizard .header-row {
	margin-left: 20px;
}

.transformer-wizard .transformer-row {
	border-left: 1px solid #ccc;
}

.transformer-wizard .transformer-row.group {
	margin: 15px 0;
}

.transformer-wizard .CodeMirror {
	height: 34px;
}

.transformer-wizard .transformer-add-row {
	height: 34px;
	overflow: visible;
}

.transformer-wizard .no-top-border,
.transformer-wizard .no-bottom-border {
	position: relative;
}

.transformer-wizard .no-top-border:before {
	background: white;
	content: '';
	height: 50%;
	left: -2px;
	position: absolute;
	top: 0;
	width: 4px;
}

.transformer-wizard .transformer-row.no-top-border:before {
	bottom: 50%;
	height: auto;
	top: -15px;
}

.transformer-wizard .no-bottom-border:after {
	background: white;
	bottom: -1px;
	content: '';
	height: 50%;
	left: -2px;
	position: absolute;
	width: 4px;
}

.transformer-wizard .builder-column {
	border-right: 1px solid #dadce4;
}

.transformer-wizard .builder-column + .builder-column {
	border-left: 1px solid #dadce4;
	border-right: none;
	margin-left: -1px;
	padding-left: 40px;
}

.transformer-wizard .builder-column + .builder-column .transformer-wizard-title {
	left: 40px;
}

.transformer-wizard .remove-column {
	text-align: center;
	width: 32px;
}

.addon-right {
	border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

@media only screen and (max-width: 991px) {
	.transformer-wizard .builder-column {
		border-right: none;
	}

	.transformer-wizard .builder-column + .builder-column {
		border-left: none;
		padding-left: 15px;
	}

	.transformer-wizard .builder-column + .builder-column .transformer-wizard-title {
		top: -50px;
		left: 15px;
	}

	.transformer-wizard .builder-column + .builder-column .mTop40 {
		margin-top: 75px;
	}
}

.ui-select-bootstrap .ui-select-match-text span {
	width: auto;
}

span[class^='glyphicon'], span[class*=' glyphicon']{
	width:auto !important;
}

/*the next 4 styles fix overflow on the ui-select*/
.ui-select-toggle {
	display: flex;
	padding-right: 28px;
}

.ui-select-match-text {
	overflow-x: hidden;
	text-overflow: ellipsis;
}

.ui-select-bootstrap > .ui-select-choices {
	overflow: auto; /*adds scrollbar*/
}

.ui-select-bootstrap .ui-select-match-text span {
	display: initial;
}

.ui-select-choices-group {
	display: grid;
}

.nonprintable-character {
    /* display as inline-block with a min width so that empty space characters are visible */
    display: inline-block;
    min-width: 0.125rem;
	background-color: #feffa7;
	font-family: "Times New Roman", Times, serif;
}

.diff-string ins {
	background: #e6ffe6;
	text-decoration: none;
}

.diff-string del,
.diff-string del .nonprintable-character {
	background: #ffe6e6;
}

.table-options .table-options-container .text-muted {
	color: #a1a5b8;
}

.table-options .table-options-container .checkbox input[type="checkbox"] {
	cursor: pointer;
	margin-top: 3px;
}

.table-options .table-options-container {
	background: #f1f2f5;
	border-radius: 2px;
	border: 1px solid #dadce4;
	display: inline-block;
	margin: 0 0 20px;
	padding: 5px 15px;
}

/* binary tree dependency view */
/* from https://gist.github.com/dylancwood/7368914 */
ul.tree {
	padding-left: 0;
    position: relative;
    left: -15px;
}

ul.tree, ul.tree ul {
	list-style: none;
}

ul.tree ul {
	padding-left: 25px;
}

ul.tree li {
	margin: 0;
	padding: 0 1px;
	line-height: 21px;
	color: #333;
	/* font-weight: bold; */
	border-left: 2px solid rgb(100, 100, 100);

}

ul.tree li:last-child {
	border-left: none;
}

ul.tree li:before {
	position: relative;
	top: -0.3em;
	height: 0.8em;
	width: 11px;
	color: white;
	border-bottom: 2px solid rgb(100, 100, 100);
	content: "";
	display: inline-block;
	left: -2px;
}

ul.tree li:last-child:before {
	border-left: 2px solid rgb(100, 100, 100);
	margin-left: 1px;
}

.floating_draggable_container {
	text-align: right;
	margin-right: 0;
	padding-right: 0;
	padding-top: 8px;
}

.form-control.webhook-log {
	height: auto;
	white-space: pre-wrap;
}

.float-left {
	float: left;
}

/*** uib-accordion ***/
/* Remove unclickable padding and make border radius consistent */
div.panel-default div.panel-heading {
	padding: 0;
	border-radius: 3px;
}
/* Fix bottom fill radius when expanded */
div.panel-default.panel-open .panel-heading {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
/* Hide underline */
div.panel-default div.panel-heading .panel-title a {
	text-decoration: none;
}
/* Make entire accordion header clickable */
div.panel-default div.panel-heading .panel-title a span div {
	padding: 10px 15px;
}
/*** uib-accordion ***/

@media print {
	/* remove spacing on top of page and grey bars on sides when printing */
	#main-content-container {
		margin-top: 0;
	}
}

/* Replicating bootstrap 5 generated class 
   Does not need to be migrated to current Angular.*/
.overflow-hidden {
    overflow: hidden;
}
