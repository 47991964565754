@import '../feedonomics/fdx-colors';

$enable-shadows: true;

$variable-prefix:   fdx-; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix:            $variable-prefix;

// Color overrides
$primary:       $primary-600;
$secondary:     $secondary-600;
$success:       $success-600;
$info:          $info-600;
$warning:       $warning-600;
$danger:        $danger-600;

/** The $light and $dark themes should not be used as a theme for different components. They are only being overridden
 * here to allow the use of the -light and -dark utility classes, such as text-light, or bg-dark. When referencing the
 * colors themselves in scss, use these $light and $dark variable references over the direct ones, $secondary-100 and
 * $secondary-900.
 */
$light:         $secondary-100;
$dark:          $secondary-900;

$hover-shade-amount: 6%;
$active-shade-amount: 12%;

$theme-colors: (
    'primary':    $primary,
    'secondary':  $secondary,
    'success':    $success,
    'info':       $info,
    'warning':    $warning,
    'danger':     $danger,
    'light':      $light,
    'dark':       $dark,
);

@import 'bootstrap/scss/functions';
@import './functions';

$discovery: $discovery-600;

$custom-colors: (
    'discovery':   $discovery
);

$theme-colors: map-merge($theme-colors, $custom-colors);

$base-theme-colors: $theme-colors;

$light-primary:     $primary-100;
$light-secondary:   $secondary-100;
$light-success:     $success-100;
$light-info:        $info-100;
$light-warning:     $warning-100;
$light-danger:      $danger-100;
$light-discovery:   $discovery-100;

$light-theme-colors: (
    'light-primary':    $light-primary,
    'light-secondary':  $light-secondary,
    'light-success':    $light-success,
    'light-info':       $light-info,
    'light-warning':    $light-warning,
    'light-danger':     $light-danger,
    'light-discovery':  $light-discovery
);

$theme-colors: map-merge($theme-colors, $light-theme-colors);

// Contrast
/** WCAG 2.0 level AA requires a contrast ratio of at least 4.5:1 for normal text and 3:1 for large text.
 * WCAG 2.1 requires a contrast ratio of at least 3:1 for graphics and user interface components (such as form input borders).
 * WCAG Level AAA requires a contrast ratio of at least 7:1 for normal text and 4.5:1 for large text.
 * Large text is defined as 14 point (typically 18.66px / >1.125rem) and bold or larger, or 18 point (typically 24px / 1.5rem) or larger.
*/
$min-contrast-ratio: 4.5;

// Body
$text-color:    $dark;  // Boostrap 3
$body-color:    $dark;
:root {
    --fdx-body-color:   #{$body-color};
}

// Typography
$font-family-sans-serif: 'Inter', sans-serif;
$font-family-monospace: 'Roboto Mono', monospace;
:root {
    --fdx-font-sans-serif: #{$font-family-sans-serif};
    --fdx-font-family-monospace: #{$font-family-monospace};
}

$font-weight-medium: 500;

$font-size-base: 0.875rem;

$h1-font-size:  1.75rem;
$h2-font-size:  1.5rem;
$h3-font-size:  1.25rem;
$h4-font-size:  1.125rem;
$h5-font-size:  1rem;
$h6-font-size:  0.875rem;

$font-sizes: (
    1: $h1-font-size,
    2: $h2-font-size,
    3: $h3-font-size,
    4: $h4-font-size,
    5: $h5-font-size,
    6: $h6-font-size
);

$headings-font-weight: 700;
$headings-line-height: 1.3;

// Gutters
$grid-gutter-width: 1.5rem;

// Components
$border-radius:         0.25rem;
$border-radius-lg:      0.5rem;
$border-color:          $secondary-200;
$table-th-font-weight:  600;
$table-border-color:    $secondary-200;
:root {
    --fdx-border-width:             1px;
    --fdx-border-style:             solid;
    --fdx-border-color:             #{$table-border-color};
    --fdx-border-radius:            #{$border-radius};
    --fdx-border-color-translucent: #{$table-border-color};
    --fdx-border-radius-pill:       50rem;
    --fdx-border-radius-lg:         #{$border-radius-lg};
}

// Box shadow
$box-shadow-inset:  none !important;

// Buttons
:root {
    --fdx-link-color:                #{$primary};
    --fdx-link-hover-color:          #{$primary-700};
}

$floating-btn-size:     35px;
$floating-btn-sm-size:  28.38px;
$floating-btn-lg-size:  44.25px;

// Buttons + Forms
$input-btn-focus-width:         .125rem;
$input-btn-focus-color-opacity: .25;
$input-btn-focus-color:         rgba($primary, $input-btn-focus-color-opacity);
$input-btn-focus-blur:          0;
$input-btn-focus-box-shadow:    0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color;

$btn-font-weight:               500;
$btn-box-shadow:                inset 0 1px 0 0 rgba(255, 255, 255, 0.2), 0 1px 0 0 rgba(0, 0, 0, 0.1);
$btn-disabled-opacity:          1;
$btn-active-box-shadow:         none;
$btn-link-disabled-color:       $secondary-600;

$btn-hover-bg-shade-amount:       $hover-shade-amount;
$btn-hover-bg-tint-amount:        $hover-shade-amount;
$btn-hover-border-shade-amount:   $hover-shade-amount;
$btn-hover-border-tint-amount:    $hover-shade-amount;
$btn-active-bg-shade-amount:      $active-shade-amount;
$btn-active-bg-tint-amount:       $active-shade-amount;
$btn-active-border-shade-amount:  $active-shade-amount;
$btn-active-border-tint-amount:   $active-shade-amount;

// Form Labels
$form-label-font-weight: 600;
$form-label-margin-bottom: 0.313rem;
$form-label-font-size: $font-size-base;

// Form Inputs
$invalid-input-focus-border-color:  $danger;
$invalid-input-focus-box-shadow:    0 0 $input-btn-focus-blur $input-btn-focus-width rgba($invalid-input-focus-border-color, 25%);
$enable-validation-icons:           false;
$input-disabled-bg:                 $light;
$input-disabled-color:              $secondary-400;

// Navs
$nav-link-color:                    $dark;
$nav-link-hover-color:              shade-color($dark, 15%);

$nav-tabs-border-color:             transparent;
$nav-tabs-border-width:             0;
$nav-tabs-link-hover-border-color:  transparent;
$nav-tabs-link-active-color:        $dark;
$nav-tabs-link-active-bg:           transparent;
$nav-tabs-link-active-border-color: $primary;

$nav-pills-link-active-color:       $primary;
$nav-pills-link-active-bg:          $light-primary;

// Dropdowns
$dropdown-link-hover-bg:            $light-primary;
$dropdown-link-active-bg:           $primary-200;
$dropdown-link-active-color:        $dark;

// Cards
$card-border-radius: $border-radius-lg;
$card-cap-bg: #ffffff;

// Accordion
$accordion-border-radius:       $border-radius-lg;
$accordion-button-active-bg:    $light-primary;
$accordion-button-active-color: $primary;

// Modals
$modal-dialog-margin:   3rem !important;

// Alerts
$alert-border-radius:   $border-radius-lg;
$alert-bg-scale:        -90%;
$alert-border-scale:    0%;
$alert-color-scale:     0%;

// List group
$list-group-hover-bg:               fdx-shade-color(#ffffff, $hover-shade-amount);

$list-group-active-color:           $dark;
$list-group-active-bg:              $primary-100;
$list-group-active-border-color:    $border-color;

$list-group-action-color:           $dark;
$list-group-action-hover-color:     $dark;

$list-group-action-active-color:    $dark;
$list-group-action-active-bg:       $primary-100;

// Breadcrumb
$breadcrumb-font-size:              0.875em;
$breadcrumb-margin-bottom:          0.75rem;
$breadcrumb-divider-color:          $secondary;
$breadcrumb-active-color:           $secondary;
$breadcrumb-divider:                null;

// Offcanvas
$offcanvas-padding-x:   1.5rem;

@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

// Temporary fix for some overridden Bootstrap color styles not working in shadow dom
@each $color, $value in $theme-colors {
    .text-#{$color} {
        color: $value !important;
    }

    .bg-#{$color} {
        background-color: $value !important;
    }

    .border-#{$color} {
        border-color: $value !important;
    }

    :root {
        --fdx-white-rgb: #{to-rgb($white)};
        --fdx-black-rgb: #{to-rgb($black)};
    }
}

:root {
    --fdx-white-rgb: #{to-rgb($white)};
    --fdx-black-rgb: #{to-rgb($black)};
}
