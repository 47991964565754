@use 'frontend/src/styles/bootstrap5/variables';

.input-with-button {

    .input-with-button-button {
        
        &.clear-btn {
            background: none;
            border: none;
            cursor: pointer;
            padding: 0 3px;
            position: relative;
            color: variables.$primary;

            &:hover {
		        color: variables.$primary-700 !important;
            }
        }
    }
    
    .input-with-button-input {
        border: none;
        padding: 0;
        width: calc(100% - 44px);

        &:focus-visible {
            outline: none;
        }
    }
}
