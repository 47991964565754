/* Custom Bootstrap 3.4.1 theme for Feedonomics */
/* Custom Vendor Components */

/* ng-toast */

.ng-toast .ng-toast__list {
    list-style-type: none;
    padding-left: 0;
}

.ng-toast .ng-toast__message {
    box-shadow: 0px 8px 16px rgba(39, 40, 51, 0.2);
    min-width: 250px;
    border-radius: 8px;
}

.ng-toast .alert-success {
    border: solid 1px #47bb7a;
    background-color: #e8f6ea;
    color: #167450;
}

.ng-toast .alert-info {
    border: solid 1px #3fb4c9;
    background-color: #eaf5f4;
    color: #0d6f84;
}

.ng-toast .alert-warning {
    border: solid 1px #ea9214;
    background-color: #fff0da;
    color: #9c530b;
}

.ng-toast .ng-toast__icon {
    padding-right: 5px;
}

.ng-toast .alert .close {
    color: #262832;
    font-size: 24px;
    font-weight: normal;
    opacity: 1;
}

.ng-toast .alert-success .close {
    color: #167450;
}

.ng-toast .alert-success .close:hover {
    color: #14553e;
}

.ng-toast .alert-info .close {
    color: #0d6f84;
}

.ng-toast .alert-info .close:hover {
    color: #0e5262;
}

.ng-toast .alert-warning .close {
    color: #9c530b;
}

.ng-toast .alert-warning .close:hover {
    color: #733d0e;
}

.ng-toast .alert-danger {
    background-color: #ffeff0;
    border-color: #f28792;
}

.ng-toast .alert-danger .close {
    color: #bd3044;
}

.ng-toast .alert-danger .ng-toast__icon {
    color: #bd3044;
}

.ng-toast .alert-danger .close:hover {
    color: #911c2f;
}

/* block-ui */

.block-ui .block-ui-overlay {
    background: #262832 75%;
}

.block-ui .block-ui-message {
    background-color: #f1f2f5;
    border: 1px solid #1f57ff;
    color: #1f57ff;
    font-size: 14px;
    font-weight: normal;
    padding: 10px;
}

.block-ui .block-ui-spinner {
    position: relative;
    margin-right: 5px;
}

.block-ui .block-ui-spinner img {
    height: 15px;
    margin-top: -3px;
    width: 15px;
}

/* Custom Feedonomics UI components */

/* fdx-toast */
.fdx-toast {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 2000;
}

.fdx-lower-toast{
    top: 60px !important;
}

/* fdx-chip */

.fdx-chip {
    background-color: #f1f2f5;
    border-radius: 20px;
    display: inline-block;
    padding: 0 10px;
    white-space: nowrap;
}

.fdx-chip .fa-circle {
    font-size: 9px;
    margin-right: 4px;
}

/* fdx-dropdown-menu */

.fdx-dropdown-menu li {
    color: #62647d;
}

.fdx-dropdown-menu li:hover, .fdx-dropdown-menu li:focus, .fdx-dropdown-menu li:hover a, .fdx-dropdown-menu li:focus a {
    background-color: #F1F8FC;
    color: #262832 !important;
}

/* fdx-modal */

.fdx-modal .modal-header, .fdx-modal .modal-body, .fdx-modal .modal-footer {
    padding: 15px 30px;
}

.fdx-modal .modal-header .modal-close {
    position: absolute;
    top: 18px;
    right: 32px;
    font-size: 20px;
    cursor: pointer;
    opacity: 0.8;
}

.fdx-modal .modal-header .modal-close:hover {
    opacity: 1;
}

.fdx-modal .modal-header .modal-close button {
    display: inline-block;
    border: none;
    margin: 0;
    padding: 0 4px;
    line-height: 1;
    background: transparent;
    -webkit-appearance: none;
}

.fdx-modal .modal-header .modal-title {
    box-sizing: border-box;
    font-size: 20px;
    font-weight: bold;
    max-height: 64px;
}

.fdx-modal.modal-backdrop.in {
    background-color: #262832 !important;
    opacity: 50%;
}

.fdx-modal.modal-dialog-centered {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fdx-modal.modal-dialog-centered > .modal-dialog {
    /*
    required to keep very tall modals from
    overflowing outside the modal-window
    */
    min-height: 0;
}
